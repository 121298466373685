@import "svg-dimensions";

@mixin wide {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 5vw;
  height: 5vw;
  z-index: 5;
}

html,
body {
  overflow: hidden;
  
  @include phone {
    overflow: initial;
  }
}

body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-size: 17px;
  letter-spacing: 0.06em;
  font-weight: 300;
  
  @include phone {
    font-size: 16px;
    height: auto;
    min-height: 100vh;
  }
}

h1,
h2,
h3 {
  margin: 30px 0 7px;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 600;
  
  &:first-child {
    margin-top: 0;
  }
}

h3 {
  font-size: .8em;
}

h4,
h5 {
  font-weight: 300;
  margin-bottom: 1em;
}

p,
ul,
ol {
  margin-bottom: 20px;
}

ul,
ol {
  margin-left: 1.5em;
}

a {
  color: inherit;
}

.icon {
  fill: currentColor;
  pointer-events: none;
}

.image-ratio,
.icon-ratio {
  position: relative;
  
  img,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.icon-ratio {
  display: block;
}

.aside {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 50px;
  
  @include phone {
    width: 100%;
    padding: 20px;
    min-height: 50vh;
    position: relative;
    flex-direction: column;
    padding-bottom: 0;
    flex-wrap: nowrap;
    background-position: center;
  }

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: left top;
  }
}

.logo {
  margin-bottom: 10px;
  width: 260px;
  color: inherit;
    
  @include phone {
    width: 100px;
  }
}

.menu {
  margin-top: 306px;
  margin-left: 21px;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  @include phone {
    order: 1;
    flex: 0 0 auto;
    margin: 10px 0 20px;
    height: auto;
  }
  
  &-link {
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    
    @include phone {
      padding: 5px 0;
    }
    
    &.is-active {
      font-weight: 500;
    }

    &--external {
      .icon {
        vertical-align: super;
      }
    }
  }
}

.main {
  position: absolute;
  left: 463px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding: 100px;
  min-height: 50vh;
  -webkit-overflow-scrolling: touch;
  
  @include phone {
    padding: 25px 20px;
    position: static;
    overflow: initial;
    -webkit-overflow-scrolling: auto;
  }
  
  .container {
    min-height: 0;
    
    > p,
    > figure {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.page-entry {
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &-aside {
    margin-bottom: 1em;
    font-size: 1.5em;
    
  }

  &-content {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.gig {
  padding-bottom: 9px;
  margin-bottom: 9px;
  border-bottom: 1px solid hsla(0,0%,0%,.13);
  display: flex;
  
  @include phone {
    flex-wrap: wrap;
  }

  &.is-hidden {
    display: none;
  }

  &-date,
  &-text {
    margin-top: 3px;
  }
  
  &-date {
    width: 33%;
    
    @include wide {
      width: 20%;
    }
    
    @include phone {
      width: 35%;
      font-size: 14px;
    }
  }

  &-show-more {
    @extend %clickable;
    background: none;
    margin-top: 9px;
    border: none;
    border-radius: 0;
    appearance: none;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }

  &-text {
    flex: 1;
    
    @include phone {
      font-size: 14px;
      flex: 1 0 65%;
    }

    &:not(:last-child) {
      margin-right: 10px;
      
      @include phone {
        margin-right: 0;
        margin-bottom: 7px;
      }
    }
  }

  &-details {
    font-size: 12px;
    
    p {
      display: inline;
    }
  }

  &-ticket {
    margin-left: auto;
    
    @include phone {
      margin-left: 35%;
    }

    &-link {
      color: inherit;
      border-radius: 3px;
      border: 1px solid hsla(0,0%,0%,.2);
      box-shadow: 0 1px 8px hsla(0,0%,0%,.08);
      white-space: nowrap;
      display: block;
      font-size: 11px;
      display: flex;
      text-decoration: none;
    }
    
    span {
      display: inline-block;
      padding: 6px;
      letter-spacing: 0.03em;
    }

    &-price {
      border-radius: 2px 0 0 2px;
      border-right: 1px solid hsla(0,0%,0%,.2);
    }
  }

  &.page-alert {
    font-style: italic;
    opacity: .5;
    border-bottom: none;
  }
}

.contact {
  @include phone {
    p {
      font-size: 14px;
    }
  }
}

figure {
  margin-bottom: 50px;
  
  @include phone {
    margin-bottom: 15px;
  }
  
  img {
    width: 100%;
    height: auto;
  }
}

.home {
  @include phone {
    .aside {
      height: 100vh;
    }
      
    .aside-background {
      object-position: center;
    }

    .social-media {
      position: static;
      filter: none;
      order: 1;
      margin-top: 0;
      justify-content: flex-start;
      
      a.initiative-musik {
        flex-basis: auto;
        text-align: left;
        margin-right: 8px;
      }
    }

    .footer {
      position: absolute;
      left: 20px;
      padding: 0;
    }
  }
}

.video {
  position: relative;
  padding-top: 56.25%;
  background: hsla(0,0%,0%,.1);
  
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 5px solid hsla(0,0%,0%,.1);
    border-right-color: hsla(0,0%,0%,.5);
    animation: loader 1s linear infinite;
  }
  
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes loader {
  to {
    transform: translate3d(-50%, -50%, 0) rotate(1turn);
  }
}

.photos {
  main {    
    .container {
      margin-left: -25px;
      margin-top: -25px;
      
      @include phone {
        margin-left: -15px;
        margin-top: -15px;
      }
    }
  }

  figure {
    margin: 25px 0 0 25px;
    width: calc(50% - 25px);
      
    @include phone {
      margin: 15px 0 0 15px;
      width: calc(50% - 15px);
    }
  }
}

.contact {
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include phone {
      display: block;
    }
  }
}

.social-media {
  display: flex;
  flex-basis: 100%;
  align-self: flex-end;
  margin-top: auto;
  margin-left: -7px;
  margin-bottom: 22px;
  flex-wrap: wrap;
  align-items: flex-end;

  @include phone {
    flex-basis: auto;
    align-self: flex-start;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin-bottom: 0;
  }
  
  a {
    font: 0/0 a;
    display: block;
    width: 40px;
    height: 40px;
    color: inherit;
    
    span {
      color: transparent;
    }
    
    &.initiative-musik {
      width: auto;
      
      @include phone {
        flex-basis: 100%;
        text-align: right;
        margin-right: 0;
      }
    }
    
    &.facebook {
      background-image: url(../images/facebook.svg);
    }
    
    &.instagram {
      background-image: url(../images/instagram.svg);
    }
    
    &.soundcloud {
      background-image: url(../images/soundcloud.svg);
    }
    
    &.spotify {
      background-image: url(../images/spotify.svg);
    }
  }
}

.footer {
  position: absolute;
  left: 50px;
  bottom: 20px;
  
  @include phone {
    position: static;
    padding: 20px 20px;
  }
  
  a {
    @extend .menu-link;
    font-size: .75em;
    opacity: .6;
    
    &.is-active {
      opacity: 1;
    }
  }
}

/*
 :::  Fade in
-------------------------------------------------- */

figure:not(.figure--noAnimation) {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(100px);
  will-change: opacity, transform;
  
  @include phone {
    transform: translateY(33px);
  }
  
  &.figure--activate {
    opacity: 1;
    transform: none;
  }
}